<template>
<Pivot report='http://127.0.0.1:8000/api/admin-backoffice/statistique-test' :toolbar=true />
</template>

  
<script>
//import api from '@/serviceApi/apiService'
export default {

    data() {
        return {
            toolbar: true,

            report: {},
    getjson:[],

    

        }
    },

    created() {

        this.readAll();

    },

    methods: {

       Readjson: async function () {
         this.getjson=[{
        "Category": {
            type: "level",
            hierarchy: "Food"
        },
        "Item": {
            type: "level",
            hierarchy: "Food",
            level: "Dish",
            parent: "Category"
        },
        "Serving Size": {
            type: "level",
            hierarchy: "Food",
            level: "Size",
            parent: "Dish"
        },
        "Calories": {
            type: "number"
        },
        "Calories from Fat": {
            type: "number"
        }
    },
    {
        "Category": "Breakfast",
        "Item": "Frittata",
        "Serving Size": "4.8 oz (136 g)",
        "Calories": 300,
        "Calories from Fat": 120
    }];

   return this.report= {
        dataSource: {
            data: getJSONData()
        },
        formats: [{
            name: "calories",
            maxDecimalPlaces: 2,
            maxSymbols: 20,
            textAlign: "right"
        }],
        slice: {
            rows: [{
                uniqueName: "Food"
            }],
            columns: [{
                uniqueName: "[Measures]"
            }],
            measures: [{
                uniqueName: "Calories",
                aggregation: "average",
                format: "calories"
            }]
        }
    }

  
   
        },

    },

};
</script>
